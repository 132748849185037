.nav-bar {
    background: #f3aca6;
    width: 64px;
    height: 100%;
    position: absolute;
//     top: 0;
    z-index: 3;
//     min-height: 500px;

    .logo{
        img{
            display: block;
            width: 52px;
            height: auto;
            padding: 5px;
            margin: 10px 0px;
            width: 54px;
        }
    }
    nav {
        display: block; //block like legos display
        text-align: center; //centers horizontally
        position: absolute;
        //height: 210px;
        top: 50%; //sets to 50% of page or centers vertically
        margin-top: -120px; //spacing from top
        width: 100%; //take up entire nav bar horiztonally

        a {
            font-size: 22px; //size of icons
            color: #b7676b;
            display: block;
            //margin: auto;

            
            line-height: 51px; //first icon spacing
            height: 51px; //spacing from first icon
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #b7676b;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after { //after button pressed make disappear
                content: '';
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                    font-weight: 900;
                    letter-spacing: 1px;
                }
            }
        }

        a.about-link {
            &:after{
                content: 'ABOUT';
                font-weight: 900;
                letter-spacing: 1px;
            }
        }

        a.contact-link {
            &:after{
                content: 'CONTACT';
                font-weight: 900;
                letter-spacing: 1px;
            }
        }

        a.projects-link {
            &:after{
                content: 'PROJECTS';
                font-weight: 900;
                letter-spacing: 1px;
            }
        }

        a.active {
            svg {
                color: #b7676b;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a{
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 16px;

                &:hover svg {
                    color: #b7676b;
                }
            }
            a.about-link {
                &:after{
                    content: 'ABOUT';
                    font-weight: 900;
                    letter-spacing: 1px;
                }
            }
    
            a.contact-link {
                &:after{
                    content: 'CONTACT';
                    font-weight: 900;
                    letter-spacing: 1px;
                }
            }
    
            a.projects-link {
                &:after{
                    content: 'PROJECTS';
                    font-weight: 900;
                    letter-spacing: 1px;
                }
            }
    
            a.active {
                svg {
                    color: #b7676b;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .nav-bar{
        //z-index: 100;
        width: 100%;
        height: 80px;
        position: absolute;
        .logo{
            img{
                height: 70px;
                width: auto;
                padding: 7px 5px 3px;
                margin: 0px 10px;
            }
        }
        nav{
            display: inline-block; //block like legos display
            height: 50px;
            margin: 0px 0px;
            top: 15px; //sets to 50% of page or centers vertically
            a{
                font-size: 35px;
                //height: 32px; //of container
                // padding:24px 0px; //this + font-size should = height
                width: 65px;//of container
                display: inline-block;
                line-height: 50px;
                &:first-child {
                    &::after {
                        //content: 'HOME';
                        font-size: 15px;
                        font-weight: 1500;                        //letter-spacing: 1px;
                    }
                }
            }
            a.about-link {
                &:after{
                    //content: 'ABOUT';
                    font-size: 15px;
                    font-weight: 1500;
                    //letter-spacing: 1px;
                }
            }
    
            a.contact-link {
                &:after{
                    //content: 'CONTACT';
                    font-size: 15px;
                    font-weight: 1500;
                    //letter-spacing: 1px;
                }
            }
    
            a.projects-link {
                &:after{
                    //content: 'PROJECTS';
                    font-size: 15px;
                    font-weight: 1500;
                    //letter-spacing: 1px;
                }
            }
    
            // a.active {
            //     svg {
            //         color: #b7676b;
            //     }
            // }
        }
        ul {
    //         //list-style-type: none;
            height: 100%;
            top: 0px;
            width: 70px;
            display: flex;
            padding: 0;
            right:0;
    //         //list-style: none;
            text-align: center;
            margin: 0px;
            justify-content: flex-end;
    
            li {
                a{
                    padding: 28px 0px;
                    display: flexbox;
                    align-items: center;
                    font-size: 24px;
                    // padding:25px;
                    // line-height: 16px;
                    width: 35px;
                    // height: 80px;
                    &:hover svg {
                        color: #b7676b;
                    }
                }
            }
        }
    }
}


@media (max-width: 500px) {
    .nav-bar{
        //z-index: 100;
        width: 100%;
        height: 80px;
        position: absolute;
        .logo{
            img{
                height: 70px;
                width: auto;
                padding: 7px 5px 3px;
                margin: 0px 10px;
            }
        }
        nav{
            display: inline-block; //block like legos display
            height: 51px;
            margin: 0px 0px;
            //margin-left: 15px;
            top: 16px; //sets to 50% of page or centers vertically
            a{
                font-size: 24px;
                //height: 32px; //of container
                // padding:24px 0px; //this + font-size should = height
                width: 40px;//of container
                display: inline-block;
                line-height: 50px;
                &:first-child {
                    &::after {
                        //content: 'HOME';
                        font-size: 10px;
                        font-weight: 1500;                        
                        letter-spacing: 0.5px;
                    }
                }
            }
            a.about-link {
                &:after{
                    //content: 'ABOUT';
                    font-size: 10px;
                    font-weight: 1500;
                    letter-spacing: 0.5px;
                }
            }
    
            a.contact-link {
                &:after{
                    //content: 'CONTACT';
                    font-size: 10px;
                    font-weight: 1500;
                    letter-spacing: 0.5px;
                }
            }
    
            a.projects-link {
                &:after{
                    //content: 'PROJECTS';
                    font-size: 8.5px;
                    font-weight: 1500;
                    letter-spacing: 0.00px;
                }
            }
    
            // a.active {
            //     svg {
            //         color: #b7676b;
            //     }
            // }
        }
        ul {
    //         //list-style-type: none;
            height: 100%;
            top: 0px;
            width: 60px;
            display: flex;
            padding: 0;
            right:0;
    //         //list-style: none;
            text-align: center;
            margin: 0px;
            justify-content: flex-end;
    
            li {
                a{
                    padding: 30px 0px;
                    display: flexbox;
                    align-items: center;
                    font-size: 20px;
                    // padding:25px;
                    // line-height: 16px;
                    width: 30px;
                    // height: 80px;
                    &:hover svg {
                        color: #b7676b;
                    }
                }
            }
        }
    }
}