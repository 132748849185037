.lsls-page{
    display: flex;
    // //margin-left: 64px;
    // //margin-top: 50px;
    // //top: 5vh;
    // //padding: 2vw;
    // text-align: center;
    // //max-width: 100%;
    // //height: 90vh;
    // //box-sizing: border-box;
    // height: 90%;
    // width: 90%;
    // padding: 5%;
    overflow-y: scroll;
    .text-zone{
        //margin-left: 64px;
        position: relative;
        margin: 5%;
        flex-direction: column;
        //vertical-align: middle;
        //display: table-cell;
        //max-height: 90%;
        align-items: center;
        justify-content: center;

        
        //max-height: vh; /* Maximum height for the text zone */
        //overflow-y: auto; /* Enable vertical scrolling */
        h1 {
            font-size: 500%;
            font-family: 'Coolvetica';
            color: #fff;
            font-weight: 400;
            //margin-top: 0;
            position: relative;
            text-align: center;
            //margin-bottom: 40px;
            //left: 10px;
        }
        h1::before {
            content: '<h1>';
            top: 40%;
            margin-left: -50px;
            // left: -25px;
            font-family: 'La Belle Aurore';
            font-size: 25px;
            position: absolute;
            opacity: 0.6;
        }
        h1::after {
            content: '</h1>';
            top: 40%;
            // bottom: -15px;
            // left: -15px;
            font-family: 'La Belle Aurore';
            font-size: 25px;
            position: absolute;
            opacity: 0.6;
        }
        
        
        .content{
            flex-direction: column;
            p {
                width: 70%;
                text-align: left;
                font-size: 315%;
                color: #fff;
                font-family: Georgia;
                font-weight: 500;
                min-width: fit-content;
                animation: pulse 1s;
                text-align: left;
    
                &:nth-of-type(1) {
                    animation-delay: 1.1s;
                }
            }
            .picture{
                text-align: center;
                width:100%;
                    //margin-left: 40%;
                flex-direction: column;
                text-align: center;
                animation: pulse 1s;
                animation-delay: 1.4s;
                    img {
                        width: 50%;
                        height: width;
                        }
        
                h3{
                    font-family: Georgia;
                    text-align: center;
                    font-size: 2vw;
                    color: white;
                }
            }

        }
        }
        

    .text-animate-hover{
        &:hover {
            color: #f3aca6;
        }
    }

    
}

@media (max-width: 1024px) {
    .lsls-page{
        .text-zone{
            h1 {
                font-size: 435%;
            }
            h1::before {
                top: 33%;
                margin-left: -40px; 
                font-size: 20px;
            }
            h1::after {
                top: 33%;
                font-size: 20px;
            }
            .content{
                flex-direction: column;
                p {
                    font-size: 250%;
                    font-weight: 500;

                }
                .picture{
                        img {
                            width: 60%;
                            height: width;
                            }
            
                    h3{
                        font-size: 150%;
                    }
                }
            }    
        }
    }
}

@media (max-width: 500px) {
    .lsls-page{
        .text-zone{
            h1 {
                font-size: 295%;
            }
            h1::before {
                top: 33%;
                margin-left: -50px; 
                font-size: 18px;
            }
            h1::after {
                top: 33%;
                margin-left: -13px;
                font-size: 18px;
            }
            .content{
                flex-direction: column;
                p {
                    font-size: 215%;
                    font-weight: 500;

                }
                .picture{
                        img {
                            width: 60%;
                            height: width;
                            }
            
                    h3{
                        font-size: 150%;
                    }
                }
            }    
        }
    }    
}