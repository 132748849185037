// // .contact-page{
// //     .text-zone{
// //         position: relative;
// //         //left: 18%;
// //         margin-left: 64px;
// //         margin-top: 40px;
// //         top: 10vh;
// //         //top: 50%;
// //         //transform:translateY(-50%);
// //         width: 70%;
// //         vertical-align: middle;
// //         place-items: center;
        
// //         max-height: 90%;
// //         overflow-y: auto;
// //         overflow-x: hidden;

// //         h1 {
// //             font-size: 4.5vw;
// //             font-family: 'Coolvetica';
// //             color: #f5a6b3;
// //             font-weight: 400;
// //             margin-top: 0;
// //             position: relative;
// //             margin-bottom: 3.75vw;

// //             //left: 10px;
// //             &::before{
// //                 color: #fff;
// //                 content: '<h1>';
// //                 font-family: 'La Belle Aurore';
// //                 font-size: 2vw;
// //                 position: absolute;
// //                 //margin-top: -10px;
// //                 //left: -1.25vw;
// //                 opacity: 0.6;
// //                 //line-height: 18px;
// //             }

// //             &::after {
// //                 content: '<h1/>';
// //                 font-family: 'La Belle Aurore';
// //                 font-size: 2vw;
// //                 //line-height: 18px;
// //                 position: absolute;
// //                 //left: -30px;
// //                 bottom: -2vw;
// //                 //margin-left: 20px;
// //                 opacity: 0.6;
// //             }
// //         }

// //         p {
// //             font-size: 2vw;
// //             color: #fff;
// //             font-family: Georgia;
// //             font-weight: 500;
// //             //min-width: fit-content;
// //             animation: pulse 1s;

// //             &:nth-of-type(1) {
// //                 animation-delay: 1.1s;
// //             }

// //             &:nth-of-type(2) {
// //                 animation-delay: 1.2s;
// //             }

// //             &:nth-of-type(3) {
// //                 animation-delay: 1.3s;
// //             }
// //         }
// //     }

// //     .text-animate-hover{
// //         &:hover {
// //             color: #fff;
// //         }
// //     }

// //     .contact-form {
// //         width: 35%;
// //         margin-top: 20px;
    
// //         ul {
// //             padding: 0;
// //             margin: 0;
// //             li {
// //                 padding: 0;
// //                 margin: 0;
// //                 list-style: none;
// //                 margin-bottom: 10px;
// //                 overflow: hidden;
// //                 display: block;
// //                 position: relative;
// //                 opacity: 0;
// //                 animation: fadeInUp 2s 2s;
// //                 animation-fill-mode: forwards;
// //                 clear: both;
// //             }
    
// //             li.half{
// //                 width: 49%;
// //                 margin-left: 2%;
// //                 float: left;
// //                 clear: none;
    
// //                 &:first-child {
// //                     margin-left: 0;
// //                 }
// //             }
// //         }
// //     }

    
// // ::placeholder{
// //     color: #7F2524;
// // }

// // input[type='text'],
// // input[type='email'] {
// //     width:100%;
// //     border-color: #7F2524;
// //     background-color: #f5a6b3;
// //     height: 48px;
// //     font-size: 16px;
// //     color: #fff;
// //     padding: 0 20px;
// //     box-sizing: border-box;
// // }

// // textarea{
// //     width: 100%;
// //     border-color: #7F2524;
// //     background-color: #f5a6b3;
// //     height: 48px;
// //     font-size: 16px;
// //     color: #fff;
// //     padding: 20px;
// //     min-height: 150px;
// //     box-sizing: border-box;
// // }

// // .send-button{
// //     color: #7F2524;
// //     font-size: 1.25vw;
// //     font-weight: 750;
// //     letter-spacing: 0.07vw;
// //     font-family: sans-serif;
// //     text-decoration: none;
// //     margin: 64px;
// //     border: 0.25vw solid #7F2524;
// //     margin-top: 0vw;
// //     padding-left: 0.75vw;
// //     padding-right: 0.75vw;
// //     padding-top: 0.5vw;
// //     padding-bottom: 0.5vw;
// //     margin-bottom: 2vw;
// //     //display: inline-block;
// //     background-color: transparent; /* Ensure the background is transparent */
// //     white-space: nowrap;
// //     border-radius: 40px;
// //     &:hover{
// //         background: #7F2524;
// //         color: #B7676B;
// //     }
// // }
// // } 




// // @media (max-width: 768px) {
// //     .contact-page{
// //         .text-zone{
// //             overflow-y: auto;
// //             overflow-x: hidden;
// //         }
// //         .send-button{
// //             font-size: 3.5vw;
// //             font-weight: 750;
// //             letter-spacing: 0.1vw;
// //             font-family: sans-serif;
// //             text-decoration: none;
// //             margin: 64px;
// //             border: 0.75vw solid #7F2524;
// //             margin-top: 0vw;
// //             padding-left: 1.25vw;
// //             padding-right: 1.25vw;
// //             padding-top: 0.5vw;
// //             padding-bottom: 0.5vw;
// //             margin-bottom: 2vw;
// //             display: inline-block;
// //             background-color: transparent; /* Ensure the background is transparent */
// //             white-space: nowrap;
// //             border-radius: 40px;
// //         }
// //     }
    
// // }

.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
//     margin-top: 40px;
//     top: 10vh;
//     height: 100vh; /* Full height of the viewport */
//     overflow-y: auto; /* Ensure content is scrollable if it overflows */
//     padding: 20px; /* Some padding for better appearance */
//     box-sizing: border-box; /* Include padding and border in element's total width and height */


    .text-zone {
    //     position: relative;
        width: 70%;
        height: 90%;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        h1 {
            font-size: 400%;
        //     margin-left: 64px;
            font-family: 'Coolvetica';
            color: #fff;
            font-weight: 400;
            position: relative;
            word-wrap: break-word; /* Wrap the text if it exceeds the container width */  
        }
        h1::before {
            content: '<h1>';
            top: -10px;
            left: -15px;
            font-family: 'La Belle Aurore';
            font-size: 50%;
            position: absolute;
            opacity: 0.6;
        }
        h1::after {
            content: '<h1/>';
            bottom: -15px;
            left: -15px;
            font-family: 'La Belle Aurore';
            font-size: 50%;
            position: absolute;
            opacity: 0.6;
        }
        p {
            font-size: 30px;
            color: #fff;
            font-family: Georgia;
            font-weight: 500;
            animation: pulse 1s;
            margin: 0; /* Reset margin */
        }
        p:nth-of-type(1) {
            animation-delay: 1.1s;
        }
    
        p:nth-of-type(2) {
            animation-delay: 1.2s;
        }
    
        p:nth-of-type(3) {
            animation-delay: 1.3s;
        }
    }

    

// .text-animate-hover{
//         &:hover {
//             color: #f3aca6;
//         }
//     }

    

  

    .contact-form {
        width: 100%; /* Ensure it takes full width of parent */
        margin: 20px auto;
        // position: relative;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        form {
            width: 100%;
        }
//     margin-bottom: 50px;
        ul {
            // padding: 0;
            // margin: 0;
            // position: relative;
            padding-left: 0px;
            width: 100%; /* Ensure ul takes full width */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // margin-bottom: 115px;
        }

        li {
            // padding: 0;
            // margin: 0 0 10px 0;
            list-style: none;
            width: 100%;
            max-width: 100%; /* Ensure li takes full width */
            // background-color: #7F2524;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
        }
        .subject{
            margin-left: 0;
            width: calc(100%);
        }
        .half {
            width: 100%;
        //     margin: 0; /* Reset margin */
            display: flex;
            flex-direction: row;
            justify-content: flex-start; /* Space out children evenly */
        }

        

        ::placeholder {
            color: #7F2524;
        }

        input[type='text'],
        input[type='email'] {
            width: 100%;
            border-color: #7F2524;
            background-color: #f5a6b3;
            height: 48px;
            font-size: 16px;
            color: #fff;
            padding: 0 20px;
            box-sizing: border-box;
        }

        textarea {
            width: 100%;
            border-color: #7F2524;
            border-width: 2px;
            background-color: #f5a6b3;
            color: #fff;
            font-size: 16px;
            // color: #fff;
            padding: 20px;
            min-height: 150px;
            box-sizing: border-box;
        }
        .button{
            display: flex;
            justify-content: center;
            //align-items: center;
            .send-button {
                margin: 10px 0px;
                color: #7F2524;
                font-size: 130%;
                font-weight: 750;
                letter-spacing: 1px;
                font-family: sans-serif;
                text-decoration: none;
                border: 0.25vw solid #7F2524;
                padding: 5px 7px;
                background-color: transparent; /* Ensure the background is transparent */
                white-space: nowrap;
                border-radius: 40px;
                //     margin-top: 20px;
                    // transition: background 0.3s, color 0.3s;
            }
            .send-button:hover {
                background: #7F2524;
                color: #B7676B;
            }
        }
    }

}

@media (max-width: 1024px) {
    .contact-page{
        .text-zone {
            width: 80%; /* Adjust the width on smaller screens */
            h1 {
                //text-align: left;
                font-size: 350%;
            }
//         h1::before{
//             font-size: 3.5vw; /* Adjust pseudo-element font-size for smaller screens */
//             bottom: 2.0vw;
//         }
//         h1::after {
//             font-size: 3.5vw; /* Adjust pseudo-element font-size for smaller screens */
//             bottom: -2.5vw;
//         }
            p{
                // padding-left: 3vh;
                // padding-right: 3vh;
                font-size: 200%;
                // padding-bottom: 0vh;
            }
        }
        .contact-form {
            margin: 10px auto;
            input[type='text'],
            input[type='email'] {
                height: 40px;
                font-size: 14px;
            }
            textarea {
                font-size: 14px;
                min-height: 120px;
            }
            .button{
                .send-button {
                    font-size: 16px;
                    border: 3px solid #7F2524;
                    padding: 2px 7px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .contact-page{
        .text-zone {
            margin: 50px 0pxs; /* Adjust the width on smaller screens */
            h1 {
                //text-align: left;
                font-size: 300%;
            }
//         h1::before{
//             font-size: 3.5vw; /* Adjust pseudo-element font-size for smaller screens */
//             bottom: 2.0vw;
//         }
//         h1::after {
//             font-size: 3.5vw; /* Adjust pseudo-element font-size for smaller screens */
//             bottom: -2.5vw;
//         }
            p{
                // padding-left: 3vh;
                // padding-right: 3vh;
                font-size: 150%;
                // padding-bottom: 0vh;
            }
        }
        .contact-form {
            margin: 10px auto;
            input[type='text'],
            input[type='email'] {
                height: 22px;
                font-size: 12px;
            }
            textarea {
                font-size: 12px;
                min-height: 80px;
            }
            .button{
                .send-button {
                    font-size: 16px;
                    font-weight: 750;
                    border: 3px solid #7F2524;
                    padding: 2px 7px;
                }
            }
        }
        
    }
}

// @media (max-width: 450px) {
//     .contact-page{
//         overflow-y: scroll;
//         margin-left: 0;
//         margin-bottom: calc(600px + 20vh);
    
//     .text-zone {
//         width: 90%; /* Adjust the width on smaller screens */
//         margin-left: 64px;
//         margin-bottom: 0vh;
//         padding-bottom: 8.5vh;


//         h1 {
//             text-align: left;
//             font-size: 10vw;
//         }
//         h1::before{
//             font-size: 3.5vw; /* Adjust pseudo-element font-size for smaller screens */
//             bottom: 2.0vw;
//         }
//         h1::after {
//             font-size: 3.5vw; /* Adjust pseudo-element font-size for smaller screens */
//             bottom: -2.5vw;
//         }

//         p{
//             padding-left: 1vh;
//             padding-right: 1vh;
//             font-size: 6vw;
//             padding-bottom: 0vh;
//         }
//     }

//     .contact-form {
//         margin-top: 0vh;
//         padding-right: 5vw;

//     }

//     .send-button {
//         font-size: 3.5vw;
//         border: 0.75vw solid #7F2524;
//         padding: 0.5vw 1.25vw;
//     }

//     .contact-form li {
//         flex-direction: column; /* Stack items vertically on smaller screens */
//     }

//     .contact-form li.half {
//         width: 100%; /* Ensure full width on smaller screens */
//         margin-bottom: 10px; /* Add some space between stacked items */
//     }
// }
// }
